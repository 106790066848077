<template>
  <b-overlay
    :show="__isOnLoad"
    variant="dark"
    rounded
    opacity="0.70"
    spinner-variant="primary"
    spinner-small
    style="float: left; width:100%;"
  >
  <div class="text-right"> 
    <base-input
      placeholder="Chercher"
      style="float: left;"
      v-model="filterStr"
    >
    </base-input>
    
    <b-button variant="outline-primary" @click="dispatchAdd()">
      <b-icon icon="clipboard-plus"></b-icon>
      {{ __editMode ? '' : 'Ajouter' }}
    </b-button>
  </div>

  <div
    :class="listStyle"
    v-for="(item, index) in __list" :key="index" 
    v-if="(filterStr == '' && catIdFilter == '-1') || (((item.__title.includes(filterStr) && filterStr !='') || filterStr == '') && ( catIdFilter == '-1'|| (catIdFilter!='-1' && item.__id_cat.includes(catIdFilter))))"
  >
    <p>
      {{item.__name}}
    </p>
      <Crtl
        :index=index
        :tab="['del','act','edit']"
        :idxOnEdition="__idxOnEdition"
        :act=Number(item.__act)
        type="crtlH"
      ></Crtl>

  </div>
  </b-overlay>
</template>
<script>
export default {
  props: {
    __idxOnEdition: {
      type: Number,
      default: () => {
        return -1;
      },
    },
    __isOnLoad: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    __list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    __header: {
      type: Array,
      default: () => {
        return [];
      },
    },
    __editMode: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  computed: {
    listStyle() {
      return this.__editMode !== false ? 'itemPrd min' : 'itemPrd full';
    },
  },
  data() {
    return {
      filterStr: '',
      catIdFilter: '-1',
    };
  },
  methods: {
    dispatchEdit(idx) {
      this.$parent.editAction(idx);
    },
    dispatchActive(idx) {
      this.$parent.toggleActivateAction(idx,1);
    },
    dispatchDesactive(idx) {
      this.$parent.toggleActivateAction(idx,0);
    },
    dispatchDelete(idx) {
      this.$bvModal.msgBoxConfirm('Veuillez confirmer la suppression.', {
        title: 'Confirmation',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Confirmer',
        cancelTitle: 'Annuler',
        footerClass: 'p-2',
        hideHeaderClose: true,
        centered: false
      })
        .then(value => {
          if(value) {
            this.$parent.deleteAction(idx);
          }
        })
    },
    dispatchAdd() {
      this.$parent.addAction();
    },
  },
};
</script>
<style scoped>
.itemPrd {
  float: left;
  border-radius: 5px;
  overflow: hidden;
  color: black;
  background: #1a1927;
  margin-bottom:5px;
}
.itemPrd p {
  padding: 9px;
  font-size:12px;
  color: white !important;
  background: #1a1927;
  float: left
}
.full {
  width: 100%;
  margin-top: 0.5%;
}
.min {
  width: 100%;
  margin-top:1%;
}

</style>
<style>
.custom-select option {
  background: #2b3553 !important;
}
</style>
