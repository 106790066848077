<template>
  <b-overlay
    :show="__isOnLoad"
    variant="dark"
    rounded
    opacity="0.70"
    spinner-variant="primary"
    spinner-small
  >
    <table class="table tablesorter">

      <tbody>
        <tr v-for="(item, index) in __list" :key="index">
          <slot :row="item">
            <td v-for="(column, index) in __header" :key="index">
              {{ item.__name }}
            </td>

            <td class="text-right">
              <b-button
                variant="success"
                size="sm"
                v-if="!__editMode && item.__act == 0"
                style="margin-left: 4px"
                @click="dispatchActive(index)"
              >
                <b-icon icon="eye-fill"></b-icon> Activer
              </b-button>
              <b-button
                variant="danger"
                size="sm"
                v-if="!__editMode && item.__act == 1"
                style="margin-left: 4px"
                @click="dispatchDesactive(index)"
              >
                <b-icon icon="eye-slash-fill"></b-icon> Désactiver
              </b-button>
              <b-button
                size="sm"
                v-if="!__editMode"
                @click="dispatchEdit(index)"
                style="margin-left: 4px"
              >
                <b-icon icon="gear-fill"></b-icon> Editer
              </b-button>
              <b-button
                variant="danger"
                size="sm"
                v-if="!__editMode"
                style="margin-left: 4px"
                @click="dispatchDelete(index)"
              >
                Supprimer
              </b-button>

              <base-button
                type="info"
                size="sm"
                v-if="__editMode && item.__act == 0 && !(index == __idxOnEdition)"
                style="margin-left: 4px"
                @click="dispatchActive(index)"
              >
                <b-icon icon="eye-fill"></b-icon>
              </base-button>
              <base-button
                type="danger"
                size="sm"
                v-if="__editMode && item.__act == 1 && !(index == __idxOnEdition)"
                style="margin-left: 4px"
                @click="dispatchDesactive(index)"
              >
                <b-icon icon="eye-slash-fill"></b-icon>
              </base-button>
              <base-button
                type="info"
                size="sm"
                @click="dispatchEdit(index)"
                style="margin-left: 4px"
                v-if="__editMode  && !(index == __idxOnEdition)"
              >
                <b-icon icon="gear-fill"></b-icon>
              </base-button>
              <base-button
                type="danger"
                size="sm"
                style="margin-left: 4px"
                v-if="__editMode  && !(index == __idxOnEdition)"
                @click="dispatchDelete(index)"
              >
                <b-icon icon="trash-fill"></b-icon>
              </base-button>
            </td>
          </slot>
        </tr>
      </tbody>
    </table>
  </b-overlay>
</template>
<script>
import { BaseButton } from '@/components';
export default {
  props: {
    __idxOnEdition: {
      type: Number,
      default: () => {
        return -1;
      },
    },
    __isOnLoad: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    __list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    __header: {
      type: Array,
      default: () => {
        return [];
      },
    },
    __editMode: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    dispatchEdit(idx) {
      this.$parent.editAction(idx);
    },
    dispatchActive(idx) {
      this.$parent.toggleActivateAction(idx,1);
    },
    dispatchDesactive(idx) {
      this.$parent.toggleActivateAction(idx,0);
    },
    dispatchDelete(idx) {
      this.$bvModal.msgBoxConfirm('Veuillez confirmer la suppression.', {
        title: 'Confirmation',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Confirmer',
        cancelTitle: 'Annuler',
        footerClass: 'p-2',
        hideHeaderClose: true,
        centered: false
      })
        .then(value => {
          if(value) {
            this.$parent.deleteAction(idx);
          }
        })
    },
    dispatchAdd() {
      this.$parent.addAction();
    },
  },
};
</script>
<style></style>
