<template>
  <b-overlay
    :show="__isOnLoad"
    variant="dark"
    rounded
    opacity="0.70"
    spinner-variant="primary"
    spinner-small
  >
  <b-modal 
  v-model="showUploader" 
  scrollable 
  :hide-footer= "true"
  :hide-header= "true"
  :dialog-class = "dataUploaderPayload.customClass"
  style="max-width: 90%;"
  >
    <galerie-manager :__payload="dataUploaderPayload"></galerie-manager>
  </b-modal>
  <div class="text-right">
    <b-button variant="outline-primary" @click="dispatchAdd()">
      <b-icon icon="clipboard-plus"></b-icon>
      Ajouter
    </b-button>
  </div>

  <div v-for="n in rangeToDisplay" :style="thumbWidth">
  <card v-for="(item, index) in purgeList(n)" :key="index" :type="cardStyle" style="margin-bottom:3%;">
    <p class="card-text">
    </p>
    <div class="author">
      <div class="block block-one"></div>
      <div class="block block-two"></div>
      <div class="block block-three"></div>
      <div class="block block-four"></div>
      <a href="#">
        <img class="avatar" :src="item.__url" alt="...">
        <h5 class="title">{{item.__title}}</h5>
      </a>

    </div>
    <div slot="footer" class="button-container">
      
      <base-button
        type="info"
        size="sm"
        v-if="item.__act == 0 && !(item.__id == __idOnEdition)"
        style="margin-left: 4px"
        @click="dispatchActive(item.__id)"
      >
        <b-icon icon="eye-fill"></b-icon>
      </base-button>
      <base-button
        type="danger"
        size="sm"
        v-if="item.__act == 1 && !(item.__id == __idOnEdition)"
        style="margin-left: 4px"
        @click="dispatchDesactive(item.__id)"
      >
        <b-icon icon="eye-slash-fill"></b-icon>
      </base-button>
      <base-button
        type="info"
        size="sm"
        @click="dispatchEdit(item.__id)"
        style="margin-left: 4px"
        v-if="!(item.__id == __idOnEdition)"
      >
        <b-icon icon="gear-fill"></b-icon>
      </base-button>
      <base-button
        type="danger"
        size="sm"
        style="margin-left: 4px"
        v-if="!(item.__id == __idOnEdition)"
        @click="dispatchDelete(item.__id)"
      >
        <b-icon icon="trash-fill"></b-icon>
      </base-button>


    </div>
  </card>
</div>
  </b-overlay>
</template>
<script>
import Galerie from '../Galerie/Galerie';
export default {
  components: {
    Galerie,
  },
  props: {
    __useCardStyle: {
      type: String,
      default: () => {
        return '';
      },
    },
    __idOnEdition: {
      type: Number,
      default: () => {
        return -1;
      },
    },
    __isOnLoad: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    __list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    __header: {
      type: Array,
      default: () => {
        return [];
      },
    },
    __editMode: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    __payloadPhotos: {
      type: Object,
      default: () => {
        return {};
      },
    },
    __useFolder: {
      type: String,
      default: () => {
        return '';
      },
    }
  },
  data() {
    return {
      showUploader: false,
      dataUploaderPayload: {
        folder: '',
        onFireCB: ()=>{},
        multiSelection: true,
        customClass: "customPhotosListModal"
      }
    };
  },
  computed: {
    thumbWidth() {
      switch(this.__useCardStyle) {
        case 'option':
        return this.__editMode !== false ? 'float: left; margin: 0.5%; width: 49%;' : 'float: left; margin: 0.5%; width: 9%;';
      }
      return this.__editMode !== false ? 'float: left; margin: 0.5%; width: 49%;' : 'float: left; margin: 0.5%; width: 19%;';
    },
    rangeToDisplay() {
      switch(this.__useCardStyle) {
        case 'option':
        return this.__editMode !== false ? 2 : 10;
      }
      return this.__editMode !== false ? 2 : 5;
    },
    cardStyle() {
      return this.__useCardStyle !== '' ? this.__useCardStyle : 'photo';
    },
  },
  methods: {
    dispatchEdit(id) {
      this.$parent.editAction(id);
    },
    dispatchActive(id) {
      this.$parent.toggleActivateAction(id,1);
    },
    dispatchDesactive(id) {
      this.$parent.toggleActivateAction(id,0);
    },
    dispatchDelete(id) {
      this.$bvModal.msgBoxConfirm('Veuillez confirmer la suppression.', {
        title: 'Confirmation',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Confirmer',
        cancelTitle: 'Annuler',
        footerClass: 'p-2',
        hideHeaderClose: true,
        centered: false
      })
        .then(value => {
          if(value) {
            this.$parent.deleteAction(id);
          }
        })
    },
    dispatchAdd() {
      this.showUploader = true;
    },
    purgeList(idx) {
      return this.__list.filter((e, index) => {
        return ((((index+1) % this.rangeToDisplay) == idx) || (((index+1) % this.rangeToDisplay) == 0 && idx == this.rangeToDisplay));
      });
    },
  },
  watch: {  
    showUploader: function () {
      this.dataUploaderPayload.folder = this.__useFolder;
    },
  },
  mounted() {
    this.dataUploaderPayload.folder = this.__useFolder;
    this.dataUploaderPayload.onFireCB = (value) => {
      for(var i=0;i<value.length;i++) {
        this.$parent.addAction(value[i]);
      }
      this.showUploader = false;
    }
  }
};
</script>
<style>
.customPhotosListModal {
    max-width: 90%;
    top: 0px;
}
</style>
