<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <b-tabs content-class="mt-3">

      <b-tab title="TEMPLATE" :active="getProfileByDisplayType() == 'CATALOGUE'">
        <div style="width: 100%">
          <template-manager :configModule="modules_template"></template-manager>
        </div>
      </b-tab>


      <b-tab title="EDITION" :active="getProfileByDisplayType() != 'CATALOGUE'" >
        <b-overlay
          :show="__isOnLoad"
          variant="dark"
          rounded
          opacity="0.70"
          spinner-variant="primary"
          spinner-small
        >
          <card>

            <div class="text-right">
              <b-button variant="danger" @click="dispatchCancel()">
                Fermer
              </b-button>
              <b-button variant="success" @click="dispatchSave()">
                Enregistrer
              </b-button>
            </div>



            <div class="col-md-12 pr-md-1">
                  <base-input
                    placeholder="Titre"
                    v-model="__item.__name"
                  >
                  </base-input>
                </div>

                <div class="col-md-12 pr-md-1" v-if="getProfileByDisplayType() == 'SITE'">
                <div  style="float: left; width:30%">
                  <b>Page d'entré</b>
                </div>
                <div  style="float: left; width:70%" >
                  <b-form-select
                    v-model="defaultNavigation"
                    :options="navigationArray"
                    class="form-control"
                  ></b-form-select> 
                </div>
              </div>
          </card>

        </b-overlay>
      </b-tab>
     

      <b-tab title="SEO" v-if="getProfileByDisplayType() !== 'SITE'">
        <Seo v-if="__payloadSeo !== {}" :__payload="__payloadSeo"></Seo>
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import Galerie from "../Galerie/Galerie";
import Seo from "../Seo/Seo";
export default {
  components: {
    Galerie,
    Seo,
  },
  props: {
    __isOnLoad: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    __item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    __payloadPhotos: {
      type: Object,
      default: () => {
        return {};
      },
    },
    __payloadSeo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    __payloadEdition: {
      type: Object,
      default: () => {
        return {};
      },
    },
    DISPLAY: {
      type: String,
      default: () => {
        return 'SHOP';
      },
    },
  },
  data: () => {
    return {
      modules_template: {},
      navigationArray: [],
      defaultNavigation: '',
      onGoingReq: [],
    };
  },
  methods: {
    dispatchSave() {
      this.$parent.saveAction();
    },

    dispatchCancel() {
      this.$parent.cancelAction();
    },

    getProfileByDisplayType() {
      switch(this.DISPLAY) {
        case "SHOP":
          return "CATALOGUE";
          break
        case "SITE":
          return "SITE";
          break
        case "PAGE":
          return "PAGE";
          break
      }
    },

    setToUseRoute() {
      switch (this.DISPLAY) {
        case "SHOP":
          return 'shops/';
          break;
        case "SITE":
          return 'sites/';
          break;
        case "PAGE":
          return 'pages/';
          break;
        default:
          return'shops/';
          break;
      }
    },

    loadNavigationData(route) {
      let $onNext = (response) => {
        response.data.result.map(e => {
          this.navigationArray.push({ text : e.__name, value: e.__id, type: route})
        });
      };
      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };
      this.onGoingReq.push({});
      this.__.httpAction({
        methode: 'GET',
        route: route+'/',
        data: null,
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },

    getConfigModulesAsTpl() {
      const parentDependencies = this._.pick(this.__item, [
        "__id",
        "__modulesID",
      ]);
      return {
        modules: this.__.getMODULESByType("MODULE", this.getProfileByDisplayType()),
        dependency: Object.values(parentDependencies),
        warpConfig: { route: this.setToUseRoute(), flagDep: ["__id", "__modulesID"] },
      };
    },

    reloadConfigs () {
      this.modules_template = this.getConfigModulesAsTpl();
    }
  },
  watch: {
    __payloadSeo: function () {
      //console.log(__payloadSeo)
    },
    __item: function () {
      this.reloadConfigs()
      setTimeout(() => {
      if(this.getProfileByDisplayType() == 'SITE') {
        this.defaultNavigation = JSON.parse(this.__item.__defaultEntry || null)?.value || '';
      }
    }, 11);
    },

    defaultNavigation: function () {
      this.__item.__defaultEntry = JSON.stringify(this.navigationArray.filter(e => e.value === this.defaultNavigation)[0])
    }
  },
  mounted() {
    this.modules_template = this.getConfigModulesAsTpl();
    setTimeout(() => {
      if(this.getProfileByDisplayType() == 'SITE') {
        this.defaultNavigation = JSON.parse(this.__item.__defaultEntry || null)?.value || '';
        this.loadNavigationData('pages');
        this.loadNavigationData('shops');
      }
    }, 11);
    
  },
};
</script>
<style>
.customPrdEditModal {
  max-width: 90%;
  top: 0px;
}

.custom-control-label {
  font-size: 10px !important;
}
</style>
