<template>
  <div class="row">
    <div class="col-md-12" v-if="onEditionId !== null">
      <Edit :__item="onEditionItem" :__isOnLoad="isEditOnload"></Edit>
    </div>
    <div :class="listStyle">
      <List
        :__list="dataArray"
        :__header="headerArray"
        :__isOnLoad="isListOnload"
        :__editMode="this.onEditionId != null"
        :__idxOnEdition="this.onEditionIdx"
      ></List>
    </div>
  </div>
</template>
<script>
import Edit from './Edit';
import List from './List';
export default {
  components: {
    Edit,
    List,
  },
  props: {
    __payload: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      moduleRoute: 'referencements/',
      payloadPhotos: {},
      onEditionId: this.$props.__payload.__id,
      onEditionIdx:0,
      onEditionItem: null,
      isListOnload: false,
      isEditOnload: false,
      headerArray: [],
      dataArray: [],
    };
  },
  computed: {
    listStyle() {
      return this.onEditionId !== null ? 'col-md-1' : 'col-md-12';
    },
  },
  methods: {
    cancelAction() {
      this.onEditionId = null;
      this.onEditionIdx = -1;
      this.onEditionItem = null;
    },
    saveAction() {
      this.isEditOnload = true;
      let $onNext = (response) => {
        this.loadData(this.onEditionId, null, this.onEditionIdx);
      };

      let $onError = (error) => {
        this.isEditOnload = false;
      };

      let $onComplete = () => {
        this.isEditOnload = false;
      };
      let diff = this.__.differ(this.dataArray[this.onEditionIdx], this.onEditionItem);
      diff.push('__id');
      let data = this._.pick(this.onEditionItem, diff);
      this.updateAction({ $onNext, $onError, $onComplete, data });
    },
    toggleActivateAction(idx, val) {
      this.isListOnload = true;
      let $onNext = (response) => {
        this.dataArray[idx].__act = val;
      };

      let $onError = (error) => {
        this.isListOnload = false;
      };

      let $onComplete = () => {
        this.isListOnload = false;
      };

      let data = this._.pick(this.dataArray[idx], ['__id', '__act']);
      data.__act = val;
      this.updateAction({ $onNext, $onError, $onComplete, data });
    },
    deleteAction(idx) {
      this.isListOnload = true;
      let $onNext = (response) => {
        if(response.data.result == 'OK') {
          this.dataArray.splice(idx, 1);
        }
      };

      let $onError = (error) => {
        this.isListOnload = false;
      };

      let $onComplete = () => {
        this.isListOnload = false;
      };

      let data = this._.pick(this.dataArray[idx], ['__id','__id_ref']);
      this.__.httpAction({
        methode: 'DELETE',
        route: this.moduleRoute,
        data: data,
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },
    editAction(idx) {
      console.log(this.dataArray)
      this.onEditionId = this.dataArray[idx].__id;
      this.onEditionIdx = idx;
      this.onEditionItem = this._.omit(this.dataArray[idx]);
    },
    updateAction(conf) {
      this.__.httpAction({
        methode: 'POST',
        route: this.moduleRoute,
        data: conf.data,
        onNext: conf.$onNext,
        onError: conf.$onError,
        onComplete: conf.$onComplete,
      });
    },
    addAction() {
      this.isListOnload = true;
      let $onNext = (response) => {
        this.loadData(response.data.result, () =>
          this.editAction(this.dataArray.length - 1)
        );
      };

      let $onError = (error) => {
        this.isListOnload = false;
      };

      let $onComplete = () => {
        this.isListOnload = false;
      };
      this.__.httpAction({
        methode: 'PUT',
        route: this.moduleRoute,
        data: null,
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },

    loadData(id = null, cb = null, putAt = null) {
      let $onNext = (response) => {
        if (id != null) {
          if(putAt != null) {
            this.dataArray[putAt] = response.data.result;
          } else {
            this.dataArray.push(response.data.result);
          }
          
          if (cb) cb();
        } else {
          this.dataArray = response.data.result;
        }
      };

      let $onError = (error) => {
        this.isListOnload = false;
      };

      let $onComplete = () => {
        this.isListOnload = false;
      };

      this.isListOnload = true;
      this.__.httpAction({
        methode: 'GET',
        route: this.moduleRoute + (id != null ? id : ''),
        data: null,
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },
  },
  watch: {  
    __payload: function () {
      setTimeout(() => {
        this.loadData(this.$props.__payload.__id, () => this.editAction(0), 0);
      }, 1000);
    },
  },
  mounted() {
    setTimeout(() => {
      this.loadData(this.$props.__payload.__id, () => this.editAction(0), 0);
    }, 1000);
    
  },
};
</script>
<style></style>
