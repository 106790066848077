<template>
  <div class="row">
      <b-form-select
      v-model="moduleID"
      :options="moduleList"
      class="mb-3"
      value-field="item"
      text-field="name"
    ></b-form-select>
    <div class="col-md-12">
      <List
        :__list="dataArray"
        :__header="headerArray"
        :__isOnLoad="isListOnload"
        :__editMode="this.onEditionId != null"
        :__idOnEdition="this.onEditionId"
        :__pidOnEdition="this.onEditionParentId"
        :__idOnShowList="this.onShowListId"
        :__idOnShowSpec="this.onShowSpecId"
        :__warpOnShowSpec="this.onShowSpecWarp"
        :__payloadPhotos="this.payloadPhotos"
        :__idOnShowSelect="this.onShowSelectId"
        :__moduleID="this.moduleID"
        :__navigationArray="this.navigationArray"
      ></List>
    </div>
  </div>
</template>
<script>

import List from './List';
export default {
  components: {
    List,
  },
  data() {
    return {
      moduleID: 'PROD_OPTS',
      moduleList: this.__.getMODULES(),
      moduleRoute: 'optionsgrp/',
      payloadPhotos: {},
      payloadSeo: {},
      onEditionId: -1,
      onEditionIdx:-1,
      onShowSpecId:-1,
      onShowSelectId: -1,
      onShowSpecWarp: '',
      onEditionParentId:-1,
      onEditionItem: null,
      onShowListId:-1,
      isListOnload: false,
      isEditOnload: false,
      navigationArray: [],
      headerArray: ['Groupe d\'options'],
      dataArray: [],
      onGoingReq: []
    };
  },
  computed: {
    
  },
  methods: {
    loadNavigationData(route) {
      let $onNext = (response) => {
        console.log(response)
        response.data.result.map(e => {
          this.navigationArray.push({ text : e.__name, value: e.__id, type: route})
        });
      };
      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };
      this.onGoingReq.push({});
      this.__.httpAction({
        methode: 'GET',
        route: route,
        data: null,
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },

    cancelAction() {
      this.onEditionId = -1;
      this.onEditionIdx = -1;
      this.onEditionItem = null;
      this.payloadPhotos = {};
    },
    showSelectAction(id) {
      this.onShowSelectId = id;
    },
    saveAction(id, pid) {
      if(pid == -1) {
        const findIdx = (element) => element.__id == id;
        var idx = this.dataArray.findIndex(findIdx);
        var arr = this.dataArray;
      } else {
        const _findIdx = (element) => element.__id == pid;
        var _idx = this.dataArray.findIndex(_findIdx);
        var arr = this.dataArray[_idx].__sub;
        const findIdx = (element) => element.__id == id;
        var idx = arr.findIndex(findIdx);
      }
      this.isEditOnload = true;
      let $onNext = (response) => {
        this.loadData(this.onEditionId, ()=>{this.cancelAction()}, idx, pid);
      };

      let $onError = (error) => {
        this.isEditOnload = false;
      };

      let $onComplete = () => {
        this.isEditOnload = false;
      };
      let diff = this.__.differ(arr[idx], this.onEditionItem);
      diff.push('__id');
      let data = this._.pick(arr[idx], diff);
      this.updateAction({ $onNext, $onError, $onComplete, data });
    },

    copieAction(item, parent) {

      const _findIdx = (element) => element.__id == parent.__parent_id;
        var _idx = this.dataArray.findIndex(_findIdx);
        var arr = this.dataArray[_idx].__sub;
        const findIdx = (element) => element.__id == item;
        var idx = arr.findIndex(findIdx);

      let $onNext = (response) => {
        this.loadData(item, ()=>{console.log('===', response)}, idx, parent.__parent_id);
      };

      let $onError = (error) => {
      
      };

      let $onComplete = () => {
        
      };
      console.log("ITEM ",arr[idx]);
      console.log("PARENT ", parent);
      let diff = this.__.differ(arr[idx], parent);
      diff = diff.filter( e => e !== '__id' && e !== '__title');
      console.log("DIFF ", diff)
      let data = this._.pick(parent, diff);
      data.__id = item;
      console.log("DATA ", data)
      this.updateAction({ $onNext, $onError, $onComplete, data });
    },

    toggleActivateAction(id, val, pid) {
      if(pid == -1) {
        const findIdx = (element) => element.__id == id;
        var idx = this.dataArray.findIndex(findIdx);
        var arr = this.dataArray;
      } else {
        const _findIdx = (element) => element.__id == pid;
        var _idx = this.dataArray.findIndex(_findIdx);
        var arr = this.dataArray[_idx].__sub;
        const findIdx = (element) => element.__id == id;
        var idx = arr.findIndex(findIdx);
      }

      
      this.isListOnload = true;
      let $onNext = (response) => {
        arr[idx].__act = val;
      };

      let $onError = (error) => {
        this.isListOnload = false;
      };

      let $onComplete = () => {
        this.isListOnload = false;
      };

      let data = this._.pick(arr[idx], ['__id', '__act']);
      data.__act = val;
      this.updateAction({ $onNext, $onError, $onComplete, data });
    },
    deleteAction(id, pid) {
      if(pid == -1) {
        const findIdx = (element) => element.__id == id;
        var idx = this.dataArray.findIndex(findIdx);
        var arr = this.dataArray;
      } else {
        const _findIdx = (element) => element.__id == pid;
        var _idx = this.dataArray.findIndex(_findIdx);
        var arr = this.dataArray[_idx].__sub;
        const findIdx = (element) => element.__id == id;
        var idx = arr.findIndex(findIdx);
      }
      this.isListOnload = true;
      let $onNext = (response) => {
        if(response.data.result == 'OK') {
          arr.splice(idx, 1);
        }
      };

      let $onError = (error) => {
        this.isListOnload = false;
      };

      let $onComplete = () => {
        this.isListOnload = false;
      };

      let data = this._.pick(arr[idx], ['__id']);

      this.__.httpAction({
        methode: 'DELETE',
        route: this.moduleRoute,
        data: data,
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },
    editAction(id, pid) {
      if(pid == -1) {
        const findIdx = (element) => element.__id == id;
        var idx = this.dataArray.findIndex(findIdx);
        var arr = this.dataArray;
      } else {
        const _findIdx = (element) => element.__id == pid;
        var _idx = this.dataArray.findIndex(_findIdx);
        var arr = this.dataArray[_idx].__sub;
        const findIdx = (element) => element.__id == id;
        var idx = arr.findIndex(findIdx);
      }
      this.onShowListId = -1;
      this.onEditionParentId = Number(pid);
      this.onEditionId = Number(arr[idx].__id);
      this.onEditionIdx = idx;
      this.onEditionItem = this._.omit(arr[idx]);
    },
    showListAction(id, pid, type, confToWarp = '') {
      if(type === "2") {
        this.onShowSpecWarp = confToWarp;
        this.onShowSpecId  = this.onShowSpecId === Number(id) ? -1 : Number(id);
        return
      }
      this.onShowSpecId  = -1;
      if(pid == -1) {

        if(id == this.onShowListId) {
          this.onShowListId  = -1;
          return;
        }
        this.onShowListId = Number(id);
        return;
       //TODO CREATE DEFAULT SUB
      } else {
        const _findIdx = (element) => element.__id == pid;
        var _idx = this.dataArray.findIndex(_findIdx);
        var arr = this.dataArray[_idx].__sub;
        const findIdx = (element) => element.__id == id;
        var idx = arr.findIndex(findIdx);
      }

      this.onShowListId  = -1;
      this.payloadPhotos  = {}
      this.onShowListId = Number(arr[idx].__id);
      this.payloadPhotos = {
        __useCnd:"2__SPL__"+this.onShowListId+"__SPL__OPTION", 
        __useIns:"1__SPL__"+this.onShowListId+"__SPL__OPTION",
        __useFolder: "options/opt"+this.onShowListId,
        __useShowFileds: ["__title", "__desc", "__price", "__price_impact"],
        __useCardStyle: "option"
      };
    },
    updateAction(conf) {
      this.__.httpAction({
        methode: 'POST',
        route: this.moduleRoute,
        data: conf.data,
        onNext: conf.$onNext,
        onError: conf.$onError,
        onComplete: conf.$onComplete,
      });
    },
    addAction(objToAdd) {
      this.isListOnload = true;
      let $onNext = (response) => {
        this.loadData(response.data.result, () => {}, null, objToAdd[0])
      };

      let $onError = (error) => {
        this.isListOnload = false;
      };

      let $onComplete = () => {
        this.isListOnload = false;
      };
      
      this.__.httpAction({
        methode: 'PUT',
        route: this.moduleRoute,
        data: {__useIns:  '1__SPL__'+objToAdd.toString().split(',').join('__SPL__')+'__SPL__'+this.moduleID},
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },

    dupAction(objToDup) {
      this.isListOnload = true;
      let $onNext = (response) => {
        this.loadData(response.data.result, () => {this.copieAction(response.data.result, objToDup)}, null, objToDup.__parent_id)
      };

      let $onError = (error) => {
        this.isListOnload = false;
      };

      let $onComplete = () => {
        this.isListOnload = false;
      };
      
      this.__.httpAction({
        methode: 'PUT',
        route: this.moduleRoute,
        data: {__useIns:  '1__SPL__'+objToDup.__parent_id+'__SPL__2__SPL__Copie de '+objToDup.__title+'__SPL__'+this.moduleID},
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },

    loadData(id = null, cb = null, putAt = null, inSubID ) {
      let $onNext = (response) => {
        if (id != null) {
           
            if(inSubID == -1) {
              if(putAt != null) {
                this.dataArray[putAt] = response.data.result;
              } else {
                this.dataArray.push(response.data.result);
              }
            } else {
              const findIdx = (element) => element.__id == inSubID;
              var idx = this.dataArray.findIndex(findIdx);
              if(putAt == null) {
                this.dataArray[idx].__sub.push(response.data.result);
              } else {
                this.dataArray[idx].__sub[putAt] = response.data.result;
              }
              
            }          
          if (cb) cb();
        } else {
          this.dataArray = response.data.result;
        }
      };

      let $onError = (error) => {
        this.isListOnload = false;
      };

      let $onComplete = () => {
        this.isListOnload = false;
      };

      this.isListOnload = true;
      this.__.httpAction({
        methode: 'GET',
        route: this.moduleRoute + (id != null ? id : ''),
        data: { __useLoop: 0,__useCnd: '0__SPL__'+this.moduleID},
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },
  },
  mounted() {
    this.loadData();
    //if(["BLOC_CONTACT"].includes(this.moduleID)) {
      this.loadNavigationData('pages/')
      this.loadNavigationData('shops/')
      this.loadNavigationData('products/')
    //}

  },
  watch: {
    moduleID: function () {
      this.loadData();
    },
  }
};
</script>
<style></style>
