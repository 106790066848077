<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <b-overlay
      :show="__isOnLoad"
      variant="dark"
      rounded
      opacity="0.70"
      spinner-variant="primary"
      spinner-small
    >
      <card>
        <b-modal
          v-model="showUploader"
          scrollable
          :hide-footer="true"
          :hide-header="true"
          :dialog-class="dataUploaderPayload.customClass"
        >
          <galerie-manager :__payload="dataUploaderPayload"></galerie-manager>
        </b-modal>

        <div class="row" style="margin-left: 10px">
          <div class="col-md-3" style="float: ;left; min-height: 100px;">
            <div
              class="row"
              style="margin-bottom: 10px"
              v-if="__item.__picto == null"
            >
              <b-button
                @click="showUploader = true; activeInputImg = '__picto'"
                size="sm"
                v-if="__useShowFileds.includes('__picto')"
              >
                Ajouter photo
              </b-button>
            </div>
            <div class="row" style="margin-bottom: 10px" v-else>
              <b-button
                @click="__item.__picto = null; activeInputImg = ''"
                size="sm"
                v-if="__useShowFileds.includes('__picto')"
              >
                Supprimer photo
              </b-button>
              <b-img
                :src="__item.__picto"
                @click="showUploader = true"
                fluid
                style="width: 100%"
                v-if="__useShowFileds.includes('__picto')"
              ></b-img>
            </div>

            <div
              class="row"
              style="margin-bottom: 10px"
              v-if="__item.__pictoOver == null"
            >
              <b-button
                @click="showUploader = true; activeInputImg = '__pictoOver' "
                size="sm"
                v-if="__useShowFileds.includes('__pictoOver')"
              >
                Ajouter photo
              </b-button>
            </div>
            <div class="row" style="margin-bottom: 10px" v-else>
              <b-button
                @click="__item.__pictoOver = null; activeInputImg = ''"
                size="sm"
                v-if="__useShowFileds.includes('__pictoOver')"
              >
                Supprimer photo
              </b-button>
              <b-img
                :src="__item.__pictoOver"
                @click="showUploader = true"
                fluid
                style="width: 100%"
                v-if="__useShowFileds.includes('__pictoOver')"
              ></b-img>
            </div>
          </div>
          <div class="col-md-9 pr-md-1" style="float: right">
            <div
              class="col-md-8 pr-md-1"
              style="float: left"
              v-if="__useShowFileds.includes('__title')"
            >
              <base-input placeholder="Titre" v-model="__item.__title">
              </base-input>
            </div>

            <div
              class="col-md-8 pr-md-1"
              style="float: left"
              v-if="__useShowFileds.includes('__video')"
            >
              <base-input placeholder="Config" v-model="__item.__video">
              </base-input>
            </div>

            <div
              class="col-md-8 pr-md-1"
              style="float: left"
              v-if="__useShowFileds.includes('__config')"
            >
              <base-input placeholder="Config" v-model="__item.__config">
              </base-input>
            </div>

            <div
              class="col-md-2 pr-md-1"
              style="float: left"
              v-if="__useShowFileds.includes('__price_impact')"
            >
              <b-form-select
                v-model="__item.__price_impact"
                class="form-control"
              >
                <b-form-select-option :value="0" style="color: #000"
                  >Gratuite</b-form-select-option
                >
                <b-form-select-option value="1" style="color: #000"
                  >Variant</b-form-select-option
                >
                <b-form-select-option value="2" style="color: #000"
                  >Extra</b-form-select-option
                >
                <b-form-select-option value="3" style="color: #000"
                  >Pourcentage</b-form-select-option
                >
              </b-form-select>
            </div>

            <div
              class="col-md-2 pr-md-1"
              style="float: left"
              v-if="__useShowFileds.includes('__price')"
            >
              <base-input placeholder="Coût" v-model="__item.__price">
              </base-input>
            </div>

            <div
              class="col-md-2 pr-md-1"
              style="float: left"
              v-if="__useShowFileds.includes('__icone')"
            >
              <base-input placeholder="Icone" v-model="__item.__icone">
              </base-input>
            </div>

            <div
              class="col-md-2 pr-md-1"
              style="float: left"
              v-if="__useShowFileds.includes('__btnLabel')"
            >
              <base-input
                placeholder="Label boutton"
                v-model="__item.__btnLabel"
              >
              </base-input>
            </div>

            <div
              class="col-md-2 pr-md-1"
              style="float: left"
              v-if="__useShowFileds.includes('__btnLink')"
            >
              <base-input placeholder="Lien" v-model="__item.__btnLink">
              </base-input>
            </div>

            <div
              class="col-md-12"
              style="clear: both"
              v-if="__useShowFileds.includes('__desc')"
            >
              <base-input>
                <textarea
                  rows="4"
                  cols="120"
                  class="form-control"
                  placeholder="Description"
                  v-model="__item.__desc"
                >
                </textarea>
              </base-input>
            </div>

            <div
              class="col-md-12"
              style="clear: both"
              v-if="__useShowFileds.includes('__html')"
            >
              <wysiwyg v-model="__item.__html" />
            </div>
            
            <div
              
              class="col-md-6"
              style="float: left"
              v-for="(_D, idx) in __useDropDown"
            >

              <textarea
                  v-if="_D().data(__item[__useDropDown[_D().warperDep]().warper]).length === 0"
                  rows="4"
                  cols="120"
                  class="form-control"
                  placeholder="Description"
                  v-model="__item[_D().warper]"
                >
              </textarea>

              <b-form-select
                v-else
                v-model="__item[_D().warper]"
                :options="_D().data(__item[__useDropDown[_D().warperDep]().warper])"
                class="form-control"
              ></b-form-select> 
            </div>

           

          </div>
        </div>

        <div class="text-right">
          <b-button variant="danger" @click="dispatchCancel()">
            Fermer
          </b-button>
          <b-button variant="success" @click="dispatchSave()">
            Enregistrer
          </b-button>
        </div>
      </card>
    </b-overlay>
  </div>
</template>
<script>
import Galerie from "../Galerie/Galerie";
import Seo from "../Seo/Seo";
export default {
  components: {
    Galerie,
    Seo,
  },
  props: {
    __isOnLoad: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    __item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    __useShowFileds: {
      type: Array,
      default: () => {
        return [];
      },
    },
    __useDropDown: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data: () => {
    return {
      showUploader: false,
      activeInputImg: '',
      dataUploaderPayload: {
        folder: "options/listitems",
        onFireCB: () => {},
        multiSelection: false,
        customClass: "customOptEditModal",
      },
    };
  },
  methods: {
    dispatchSave() {
      this.$parent.saveAction();
    },
    dispatchCancel() {
      this.$parent.cancelAction();
    },
  },
  mounted() {
    
  },

  watch: {
    activeInputImg: function () {
      console.log(this.activeInputImg)
        this.dataUploaderPayload.onFireCB = (value) => {
        
        if(this.activeInputImg == '__picto')
          this.$props.__item.__picto  = value[0];
        if(this.activeInputImg == '__pictoOver')
          this.$props.__item.__pictoOver  = value[0];
        this.showUploader = false;
      };
    },
  }
};
</script>
<style>
.customOptEditModal {
  max-width: 90%;
  top: 0px;
}
</style>
