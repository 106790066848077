<template>
  <b-overlay
    :show="__isOnLoad"
    variant="dark"
    rounded
    opacity="0.70"
    spinner-variant="primary"
    spinner-small
  >
    <div class="text-right">
      <b-button variant="outline-primary" @click="dispatchAdd()">
        <b-icon icon="clipboard-plus"></b-icon>
        Ajouter
      </b-button>
    </div>
    <b-card
      v-for="(item, index) in __list"
      :key="index"
      border-variant="primary"
      header-tag="header"
      header-bg-variant="purple"
      header-text-variant="white"
      :no-body="true"
    >
      <template #header>
        <div class="text-right" style="float: right">
          <b-button
            variant="success"
            size="sm"
            v-if="item.__act == 0"
            style="margin-left: 4px"
            @click="dispatchActive(item.__id)"
          >
            <b-icon icon="eye-fill"></b-icon> Activer
          </b-button>
          <b-button
            variant="danger"
            size="sm"
            v-if="item.__act == 1"
            style="margin-left: 4px"
            @click="dispatchDesactive(item.__id)"
          >
            <b-icon icon="eye-slash-fill"></b-icon> Désactiver
          </b-button>
          <b-button
            size="sm"
            @click="dispatchEdit(item.__id)"
            style="margin-left: 4px"
          >
            <b-icon icon="gear-fill"></b-icon> Editer
          </b-button>
          <b-button
            variant="danger"
            size="sm"
            style="margin-left: 4px"
            @click="dispatchDelete(item.__id)"
          >
            Supprimer
          </b-button>
        </div>

        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"
          v-if="__useShowFileds.includes('__icone')"
        />
        <span
          class="material-symbols-outlined"
          style="float: left; margin-right: 10px"
          v-if="__useShowFileds.includes('__icone')"
        >
          {{ item.__icone }}
        </span>
        <b-img
          :src="item.__picto"
          style="float: left; width: 50px; margin-right: 20px"
          fluid
          v-if="__useShowFileds.includes('__picto')"
        ></b-img>
        <h3
          style="float: left; margin-bottom: 0px"
          v-if="__useShowFileds.includes('__title')"
        >
        <b style="font-size:9px">{{item.__id}}</b> {{ item.__title }}
        </h3>
        <br />
        <h5
          style="float: left; clear: right"
          v-if="
            item.__desc &&
            item.__desc.length > 0 &&
            __useShowFileds.includes('__desc')
          "
        >
          {{ item.__desc.substring(0, 100) }}...
        </h5>
        <h8
          style="float: left; clear: right; color: #5e72e4; font-size: 10px"
          v-if="
            item.__html &&
            item.__html.length > 0 &&
            __useShowFileds.includes('__html')
          "
          v-html="item.__html.substring(0, 200)"
        ></h8>

        <h8
          style="float: left; clear: right; color: #5e72e4; font-size: 14px; margin-top: -10px; margin-bottom: 10px;"
          v-if="
            item.__btnLabel &&
            item.__btnLabel.length > 0 &&
            __useShowFileds.includes('__btnLabel')
          "
          v-html="item.__btnLabel.substring(0, 200)+getLink(item.__btnLink)"
        ></h8>
        
      </template>
    </b-card>
  </b-overlay>
</template>
<script>
import { BaseButton } from "@/components";
export default {
  props: {
    __idxOnEdition: {
      type: Number,
      default: () => {
        return -1;
      },
    },
    __isOnLoad: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    __list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    __editMode: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    __useShowFileds: {
      type: Array,
      default: () => {
        return [];
      },
    },
    __navigationArray: {
      type: Array,
      default: () => {
        return [];
      },
    },
    
  },
  data() {
    return {};
  },
  methods: {
    getLink (link) {
      var ret = this.__navigationArray.filter(e => e.value === link)
      if(ret.length === 0 ) 
        return ' > '+link
      else
        return ' > '+ret[0].text
    },
    dispatchEdit(idx) {
      this.$parent.editAction(idx);
    },
    dispatchActive(idx) {
      this.$parent.toggleActivateAction(idx, 1);
    },
    dispatchDesactive(idx) {
      this.$parent.toggleActivateAction(idx, 0);
    },
    dispatchDelete(idx) {
      this.$bvModal
        .msgBoxConfirm("Veuillez confirmer la suppression.", {
          title: "Confirmation",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Confirmer",
          cancelTitle: "Annuler",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: false,
        })
        .then((value) => {
          if (value) {
            this.$parent.deleteAction(idx);
          }
        });
    },
    dispatchAdd() {
      this.$parent.addAction();
    },
  },
};
</script>
<style></style>
