<template>
  <b-overlay
    :show="__isOnLoad"
    variant="dark"
    rounded
    opacity="0.70"
    spinner-variant="primary"
    spinner-small
  >
    <b-modal
      v-model="showUploader"
      scrollable
      :hide-footer="true"
      :hide-header="true"
      :dialog-class="dataUploaderPayload.customClass"
    >
      <galerie-manager :__payload="dataUploaderPayload"></galerie-manager>
    </b-modal>

    <b-modal
      v-model="showAddModal"
      scrollable
      :hide-footer="true"
      :hide-header="true"
      dialog-class="addModal"
    >
      <div class="row">
        <div class="col-md-12 pr-md-1">
          <base-input
            v-model="addPayload.__title"
            placeholder="Titre de l'option"
          >
          </base-input>
        </div>

        <div class="col-md-12 pr-md-1" v-if="addPayload.__parent_id == -1">
          <b-form-select
            v-model="selectedType"
            :options="optionsTypes"
            class="mb-3"
            value-field="item"
            text-field="name"
          ></b-form-select>
        </div>

        <div class="text-right">
          <b-button variant="outline-primary" @click="confirmAdd()">
            <b-icon icon="clipboard-plus"></b-icon>
            Valider
          </b-button>
        </div>
      </div>
    </b-modal>

    <div class="text-right">
      <b-button
        v-if="isExpanded === false"
        variant="outline-primary"
        @click="dispatchAdd({ __type: '-1' })"
      >
        <b-icon icon="clipboard-plus"></b-icon>
        Ajouter
      </b-button>
      <base-input
      v-if="isExpanded === false"
        placeholder="Chercher"
        style="float: left;"
        v-model="filterStr"
      >
      </base-input>
      <b-button
        v-if="isExpanded === true"
        variant="danger"
        @click="isExpanded = false; expandedItem = []"
      >
        Retour a la liste
      </b-button>
    </div>

    <div v-if="isExpanded === false" v-for="(item, index) in getList()" class="lightView" @click="isExpanded = true; expandedItem = [item]">
      <b style="font-size:9px">({{item.__id}})</b> {{item.__title}}
    </div>

    <b-card
      v-for="(item, index) in expandedItem"
      :key="index"
      border-variant="indigo"
      header-tag="header"
      header-bg-variant="purple"
      header-text-variant="white"
      :no-body="true"
    >
      <template
        #header
        v-if="
          __idOnEdition == -1 ||
          __idOnEdition == item.__id ||
          __pidOnEdition == item.__id
        "
      >
        <h3 style="float: left" v-if="__idOnEdition == -1">
          {{ item.__title }}
        </h3>
        <div class="text-right" style="float: right" v-if="__idOnEdition == -1">
          <b-button
            variant="success"
            size="sm"
            v-if="item.__act == 0"
            style="margin-left: 4px"
            @click="dispatchActive(item.__id)"
          >
            <b-icon icon="eye-fill"></b-icon> Activer
          </b-button>
          <b-button
            variant="danger"
            size="sm"
            v-if="item.__act == 1"
            style="margin-left: 4px"
            @click="dispatchDesactive(item.__id)"
          >
            <b-icon icon="eye-slash-fill"></b-icon> Désactiver
          </b-button>

          <b-button
            v-if="
              item.__type == 0 ||
              ['PROD_SPEC', 'NAV_MENU', 'PROD_SPEC_FICHE'].includes(__moduleID)
            "
            size="sm"
            @click="
              dispatchAdd({ __parent_id: item.__id, __type: item.__type })
            "
            style="margin-left: 4px"
          >
            <b-icon icon="clipboard-plus"></b-icon> Ajouter
          </b-button>

          <b-button
            size="sm"
            @click="dispatchEdit(item.__id, -1, item)"
            style="margin-left: 4px"
          >
            <b-icon icon="gear-fill"></b-icon> Editer
          </b-button>

          <b-button
            v-if="
              item.__type == 1 &&
              ![
                'PROD_SPEC',
                'NAV_MENU',
                'NAV_MENU',
                'PROD_SPEC_FICHE',
              ].includes(__moduleID)
            "
            size="sm"
            @click="
              dispatchShoList(
                item.__id,
                item.__parent_id,
                item.__type,
                ['PROD_SPEC', 'NAV_MENU'].includes(__moduleID)
              )
            "
            style="margin-left: 4px"
          >
            <b-icon icon="gear-fill"></b-icon> Liste
          </b-button>

          <b-button
            v-if="
              item.__type == 1 &&
              [
                'BLOC_ADRESSE',
              ].includes(__moduleID)
            "
            size="sm"
            @click="
              dispatchShowSelect(
                item.__id,
              )
            "
            style="margin-left: 4px"
          >
            <b-icon icon="gear-fill"></b-icon> Selectionner
          </b-button>

          <b-button
            variant="danger"
            size="sm"
            v-if="item.__sub.length == 0"
            style="margin-left: 4px"
            @click="dispatchDelete(item.__id)"
          >
            Supprimer
          </b-button>
        </div>

        <div
          class="col-md-12 pr-md-1"
          style="float: left"
          v-if="__idOnEdition == item.__id"
        >
          <base-input
            v-if="useShowFileds.edition.includes('__title')"
            v-model="item.__title"
          >
          </base-input>

          <div
              
              class="col-md-6 pr-md-1"
              style="float: left"
              v-for="(_D, idx) in useShowFileds.dropDown"
            >

              <textarea
                  v-if="_D().data(item[useShowFileds.dropDown[_D().warperDep]().warper]).length === 0"
                  rows="4"
                  cols="120"
                  class="form-control"
                  placeholder="Description"
                  v-model="item[_D().warper]"
                >
              </textarea>

              <b-form-select
                v-else
                v-model="item[_D().warper]"
                :options="_D().data(item[useShowFileds.dropDown[_D().warperDep]().warper])"
                class="form-control"
              ></b-form-select> 
            </div>

          <base-input
            placeholder="__btnLabel"
            style="width: 49%; float: left; margin-right: 1%"
            v-if="useShowFileds.edition.includes('__btnLabel')"
            v-model="item.__btnLabel"
          >
          </base-input>

          <base-input
            placeholder="__btnAction"
            style="width: 49%; float: left; margin-right: 1%"
            v-if="useShowFileds.edition.includes('__btnAction')"
            v-model="item.__btnAction"
          >
          </base-input>

          <base-input
            placeholder="__config"
            style="width: 100%; float: left; clear: both"
            v-if="useShowFileds.edition.includes('__config')"
            v-model="item.__config"
          >
          </base-input>

          <base-input
            v-if="useShowFileds.edition.includes('__desc')"
            style="clear: both"
          >
            <textarea
              rows="4"
              cols="120"
              class="form-control"
              placeholder="Description"
              v-model="item.__desc"
            >
            </textarea>
          </base-input>

          <div
            class="col-md-12"
            style="clear: both"
            v-if="useShowFileds.edition.includes('__html')"
          >
            <wysiwyg v-model="item.__html" />
          </div>

          <div
            class="col-md-6"
            style="margin-bottom: 10px"
            v-if="item.__picto == null"
          >
            <b-button
              @click="showUploader = true"
              size="sm"
              v-if="useShowFileds.edition.includes('__picto')"
            >
              Ajouter photo
            </b-button>
          </div>
          <div class="row" style="margin-bottom: 10px" v-else>
            <b-button
              @click="item.__picto = null"
              size="sm"
              v-if="useShowFileds.edition.includes('__picto')"
            >
              Supprimer photo
            </b-button>
            <b-img
              :src="item.__picto"
              @click="showUploader = true"
              fluid
              style="width: 250px"
              v-if="useShowFileds.edition.includes('__picto')"
            ></b-img>
          </div>
        </div>
        <div
          class="text-right"
          style="float: right"
          v-if="__idOnEdition == item.__id"
        >
          <b-button variant="danger" @click="dispatchCancel()">
            Fermer
          </b-button>
          <b-button variant="success" @click="dispatchSave(item.__id)"
            >Enregistrer</b-button
          >
        </div>
      </template>

      <b-card-body
        v-if="
          __idOnEdition == -1 ||
          __idOnEdition == item.__id ||
          __pidOnEdition == item.__id
        "
      >
        <b-card
          v-for="(_item, _index) in item.__sub"
          :key="_index"
          bg-variant="secondary"
          text-variant="white"
        >
          <div
            class="col-md-12 pr-md-1"
            style="float: left"
            v-if="__idOnEdition == _item.__id"
          >
            <base-input v-model="_item.__title"> </base-input>
          </div>
          <div
            v-if="
              useShowFileds.edition.includes('__picto') &&
              __idOnEdition == _item.__id
            "
            style="width: 20%"
          >
            <div
              class="col-md-6"
              style="margin-bottom: 10px"
              v-if="_item.__picto == null"
            >
              <b-button
                @click="showUploader = true"
                size="sm"
                v-if="useShowFileds.edition.includes('__picto')"
              >
                Ajouter photo
              </b-button>
            </div>
            <div class="row" style="margin-bottom: 10px" v-else>
              <b-button
                @click="_item.__picto = null"
                size="sm"
                v-if="useShowFileds.edition.includes('__picto')"
              >
                Supprimer photo
              </b-button>
              <b-img
                :src="_item.__picto"
                @click="showUploader = true"
                fluid
                style="width: 100%"
                v-if="useShowFileds.edition.includes('__picto')"
              ></b-img>
            </div>
          </div>
          <div
            class="text-right"
            style="float: right"
            v-if="__idOnEdition == _item.__id"
          >
            <b-button variant="danger" @click="dispatchCancel()">
              Fermer
            </b-button>
            <b-button
              variant="success"
              @click="dispatchSave(_item.__id, _item.__parent_id)"
              >Enregistrer</b-button
            >
          </div>

          <h4 v-if="__idOnEdition == -1" style="float: left">
            <b style="font-size:9px">{{_item.__id}}</b>  {{ _item.__title }}
          </h4>
          <div
            class="text-right"
            style="float: right"
            v-if="__idOnEdition == -1"
          >
            <b-button
              variant="success"
              size="sm"
              v-if="_item.__act == 0"
              style="margin-left: 4px"
              @click="dispatchActive(_item.__id, _item.__parent_id)"
            >
              <b-icon icon="eye-fill"></b-icon> Activer
            </b-button>
            <b-button
              variant="danger"
              size="sm"
              v-if="_item.__act == 1"
              style="margin-left: 4px"
              @click="dispatchDesactive(_item.__id, _item.__parent_id)"
            >
              <b-icon icon="eye-slash-fill"></b-icon> Désactiver
            </b-button>
            <b-button
              size="sm"
              @click="dispatchEdit(_item.__id, _item.__parent_id, _item)"
              style="margin-left: 4px"
            >
              <b-icon icon="gear-fill"></b-icon> Editer
            </b-button>
            <b-button
              size="sm"
              @click="
                dispatchShoList(
                  _item.__id,
                  _item.__parent_id,
                  _item.__type,
                  false,
                  _item.__config
                )
              "
              style="margin-left: 4px"
            >
              <b-icon icon="gear-fill"></b-icon> Liste
            </b-button>
            <b-button
              variant="danger"
              size="sm"
              style="margin-left: 4px"
              @click="dispatchDelete(_item.__id, _item.__parent_id)"
            >
              Supprimer
            </b-button>

            <b-button
            v-if="
              item.__type == 0 ||
              ['PROD_SPEC_FICHE'].includes(__moduleID)
            "
             variant="success"
            size="sm"
            @click="
              dispatchDup( _item)
            "
            style="margin-left: 4px"
          >
            <b-icon icon="clipboard-plus"></b-icon> Copier
          </b-button>
          </div>

          <b-card
            bg-variant="default"
            text-variant="white"
            style="clear: both"
            v-if="__idOnShowList == _item.__id && _item.__type == 0"
          >
            <Galerie :__payload="__payloadPhotos"></Galerie>
          </b-card>

          <b-card
            bg-variant="default"
            text-variant="white"
            style="clear: both"
            v-if="
              __idOnShowSpec == _item.__id &&
              _item.__type == 2 &&
              __idOnEdition == -1
            "
          >
            <spec-editor
              :parentID="__idOnShowSpec"
              :warp="__warpOnShowSpec"
            ></spec-editor>
          </b-card>

          <b-card
            bg-variant="default"
            text-variant="white"
            style="clear: both"
            v-if="__idOnShowList == _item.__id && item.__type == 1"
          >
            <optionList :PID="_item.__id" :CONFIG="__moduleID"></optionList>
          </b-card>
        </b-card>


        <b-card
          bg-variant="default"
          text-variant="white"
          style="clear: both"
          v-if="__idOnShowSelect == item.__id && item.__type == 1"
        >
        <select-manager
              :configModule="getConfigModuleToWarp(item.__id, item.__extendedList)"
            ></select-manager>
        </b-card>

        <b-card
          bg-variant="default"
          text-variant="white"
          style="clear: both"
          v-if="__idOnShowList == item.__id && item.__type == 1"
        >
          <optionList :PID="item.__id" :CONFIG="__moduleID"></optionList>
        </b-card>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>
<script>
import { BaseButton } from "@/components";
import Galerie from "../Galerie/Galerie";
import optionList from "../optionList/index";
export default {
  components: {
    Galerie,
    optionList,
  },
  props: {
    __payloadPhotos: {
      type: Object,
      default: () => {
        return {};
      },
    },
    __idOnShowList: {
      type: Number,
      default: () => {
        return -1;
      },
    },
    __pidOnEdition: {
      type: Number,
      default: () => {
        return -1;
      },
    },
    __idOnEdition: {
      type: Number,
      default: () => {
        return -1;
      },
    },
    __isOnLoad: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    __list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    __header: {
      type: Array,
      default: () => {
        return [];
      },
    },
    __editMode: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    __moduleID: {
      type: String,
      default: () => {
        return "DEFAULT";
      },
    },
    __idOnShowSpec: {
      type: Number,
      default: () => {
        return -1;
      },
    },
    __idOnShowSelect: {
      type: Number | String,
      default: () => {
        return -1;
      },
    },
    __warpOnShowSpec: {
      type: String,
      default: () => {
        return "DEFAULT";
      },
    },
    __navigationArray: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      filterStr: '',
      isExpanded: false,
      expandedItem: [],
      showAddModal: false,
      showUploader: false,
      dataUploaderPayload: {
        folder: "options/grplistitems",
        onFireCB: () => {},
        multiSelection: false,
        customClass: "customOptEditModal",
      },
      addPayload: {
        __parent_id: -1,
        __title: "",
        __type: "0",
      },
      selectedType: "0",
      optionsTypes: [
        { item: "-1", name: "Sans liste" },
        { item: "0", name: "Galerie photo" },
        { item: "1", name: "Liste Textuelle" },
        { item: "2", name: "Spec produit" },
      ],
      useShowFileds: { edition: ["__title"] },
    };
  },
  methods: {
    getList() {
      if(this.filterStr != '' && this.__list.length > 0) {
        return this.__list.filter(e => e.__title.includes(this.filterStr))
      }
      return this.__list;
    },
    dispatchShoList(id, pid = -1, type = 0, forceList = false, config = "") {
      if (forceList) pid = -1;
      this.$parent.showListAction(id, pid, type, config);
    },
    dispatchSave(id, pid = -1) {
      this.$parent.saveAction(id, pid);
    },
    dispatchDup(itm) {
      this.$parent.dupAction(itm);
    },
    dispatchShowSelect(id) {
      this.$parent.showSelectAction(id)
    },
    dispatchCancel() {
      this.$parent.cancelAction();
    },
    dispatchEdit(id, pid = -1, itemToManage = null) {
      (this.dataUploaderPayload.folder =
        "options/grplistitems" + itemToManage.__id),
        (this.dataUploaderPayload.onFireCB = (value) => {
          itemToManage.__picto = value[0];
          this.showUploader = false;
        });
      this.$parent.editAction(id, pid);
    },
    dispatchActive(id, pid = -1) {
      this.$parent.toggleActivateAction(id, 1, pid);
    },
    dispatchDesactive(id, pid = -1) {
      this.$parent.toggleActivateAction(id, 0, pid);
    },
    dispatchDelete(idx, pid = -1) {
      this.$bvModal
        .msgBoxConfirm("Veuillez confirmer la suppression.", {
          title: "Confirmation",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Confirmer",
          cancelTitle: "Annuler",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: false,
        })
        .then((value) => {
          if (value) {
            this.$parent.deleteAction(idx, pid);
          }
        });
    },
    dispatchAdd(obj = { __parent_id: -1 }) {
      this.addPayload = { ...this.addPayload, ...obj };
      this.showAddModal = true;
      //this.$parent.addAction();
    },
    confirmAdd() {
      this.showAddModal = false;
      this.$parent.addAction([
        this.addPayload.__parent_id,
        this.addPayload.__type !== "-1"
          ? this.addPayload.__type
          : this.selectedType,
        this.addPayload.__title,
      ]);
      this.selectedType = 0;
      this.addPayload = {
        __parent_id: -1,
        __title: "",
        __type: "0",
      };
    },

    getConfigModuleToWarp(id, val) {
      return {
        config: {
          allowOverlap: false,
          name: 'List des adresses',
          oneSelect: false,
          selectGrp: true,
          setFlag:"__extendedList",
          setRoute: 'optionsgrp/',
          skey: "PRODUCT"
        },
        dependency: [id, val],
        item: 'BLOC_ADRESSE'
      }
    },

    getFiledToUseByConfig(CONFIG) {
      switch (CONFIG) {
        case "BLOC_IMGS":
        this.useShowFileds = {
            edition: [
              "__title",
            ],
          };
          break;
        case "BLOC_ADRESSE":
          this.useShowFileds = {
            edition: [
              "__title",
              "__desc",
              "__html",
              "__config",
            ],
          };
          break;
        case "BLOC_TEXT":
          this.useShowFileds = {
            edition: [
              "__title",
              "__desc",
              "__btnLabel",
              "__btnAction",
              "__html",
              "__config",
            ],
          };
          break;
        case "FAQ":
          this.useShowFileds = {
            edition: ["__title", "__config", "__html"],
          };
          break;
        case "PROD_GRID":
        case "SCROLL_HOME":
          this.useShowFileds = {
            edition: ["__title", "__config"],
          };
          break;
        case "PROD_SPEC":
          this.useShowFileds = {
            edition: ["__title", "__desc"],
          };
          break;
        case "PROD_SPEC_FICHE":
          this.useShowFileds = {
            edition: ["__title", "__picto"],
          };
          break;
        case "NAV_MENU":
          this.useShowFileds = {
            edition: ["__title", "__config"],
          };
          break;
        case "BLOC_DIAPO":
          this.useShowFileds = {
            edition: ["__title", "__desc"],
          };
          break;
        case "BLOC_CONTACT":
          this.useShowFileds = {
            edition: [
              "__title",
              "__desc",
              "__html",
              "__picto",
              "__config",
            ],
            dropDown: [
              () => ({
                warperDep: 0,
                warper: '__btnLabel',
                data: () => ([{text: 'Nous écrire', value: 'Page'}, {text: 'Découvrez la collection', value: 'Boutique'}, {text: 'Voir ce produit', value: 'Produit'}])
              }),
              () => ({
                warperDep: 0,
                warper: '__btnLink',
                data: (dep) => {
                  if(dep === "Page") {
                    return this.$props.__navigationArray.filter(e => e.type === 'pages/')
                  }
                  if(dep === "Boutique") {
                    return this.$props.__navigationArray.filter(e => e.type === 'shops/')
                  }
                  if(dep === "Produit") {
                    return this.$props.__navigationArray.filter(e => e.type === 'products/')
                  }
                  return [];
                }
              })
           ]
          };
          break;
      }
    },
  },

  computed: {},

  watch: {
    __moduleID: function () {
      if (this.__moduleID != "") {
        this.dispatchCancel();
        this.getFiledToUseByConfig(this.__moduleID);
      }
    },
  },
};
</script>
<style>
.card h1{
  color: black !important;
}

.card h2{
  color: black !important;
}
.card {
  margin-bottom: 5px;
}
.card .bg-secondary {
  background-color: #171822 !important;
}
.addModal select {
  color: black !important;
}
.addModal input {
  color: black !important;
}
.lightView {
  border-radius: 15px;
  cursor: pointer;
  float: left;
  padding: 20px;
  margin: 10px;
  background-color: #111625;
}
.lightView:hover {

  background-color: #07090e;
}
</style>
