<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <b-overlay
        :show="__isOnLoad"
        variant="dark"
        rounded
        opacity="0.70"
        spinner-variant="primary"
        spinner-small
      >
        <card>
          <div class="row">
            <div class="col-md-12 pr-md-1">
              <base-input
                label="Tag title"
                v-model="__item.__title"
                placeholder="Tag title"
              >
              </base-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 pr-md-1">
              <base-input
                label="Tag URL"
                v-model="__item.__url"
                placeholder="Tag URL"
              >
              </base-input>
            </div>
          </div>

          <div class="col-md-12">
              <base-input>
                <textarea
                  rows="4"
                  cols="120"
                  class="form-control"
                  placeholder="Keywords"
                  v-model="__item.__keys"
                >
                </textarea>
              </base-input>
            </div>

            <div class="col-md-12">
              <base-input>
                <textarea
                  rows="4"
                  cols="120"
                  class="form-control"
                  placeholder="Description"
                  v-model="__item.__desc"
                >
                </textarea>
              </base-input>
            </div>

          <div class="row">
            <div class="col-md-12">
              <wysiwyg v-model="__item.__html" />
            </div>
          </div>
          <div class="text-right">
            <b-button variant="danger" @click="dispatchCancel()"> Fermer </b-button>
            <b-button variant="success" @click="dispatchSave()">
              Enregistrer
            </b-button>
          </div>
        </card>
        </b-overlay>
  </div>
</template>
<script>
export default {

  props: {
    __isOnLoad: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    __item: {
      type: Object,
      default: () => {
        return {
          __title: '',
          __url: ''
        };
      },
    },

  },
  data: () => {
    return {
      
    }
  },
  methods: {
    dispatchSave() {
      this.$parent.saveAction();
    },
    dispatchCancel() {
      this.$parent.cancelAction();
    },
  },
  mounted() {
  }
};
</script>
<style>

</style>
