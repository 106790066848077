<template>
  <div class="row">
    <div class="col-md-8" v-if="onEditionId !== null">
      <Edit :__item="onEditionItem" :__isOnLoad="isEditOnload" :__useFolder="this.useFolder" :__useShowFileds="this.useShowFileds"></Edit>
    </div>
    <div :class="listStyle">
      <b-form-select
        v-if="useGrpList.length > 0"
        v-model="useGrp"
        :options="useGrpList"
        class="form-control"
      ></b-form-select>
      <List
        :__list="dataArray"
        :__header="headerArray"
        :__isOnLoad="isListOnload"
        :__editMode="this.onEditionId != null"
        :__idOnEdition="this.onEditionId"
        :__useFolder="this.useFolder"
        :__useCardStyle="this.useCardStyle"
      ></List>
    </div>
  </div>
</template>
<script>
import Edit from './Edit';
import List from './List';
export default {
  components: {
    Edit,
    List,
  },
  props: {
    __payload: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      moduleRoute: 'photos/',
      onEditionId: null,
      onEditionIdx:-1,
      onEditionItem: null,
      isListOnload: false,
      isEditOnload: false,
      headerArray: [],
      dataArray: [],
      useFolder: '',
      useShowFileds: [],
      useCardStyle:'',
      useGrp: 'DEFAULT',
      useGrpList: [],
    };
  },
  computed: {
    listStyle() {
      return this.onEditionId !== null ? 'col-md-4' : 'col-md-12';
    },
  },
  methods: {
    cancelAction() {
      this.onEditionId = null;
      this.onEditionIdx = -1;
      this.onEditionItem = null;
    },
    saveAction() {
      this.isEditOnload = true;
      let $onNext = (response) => {
        this.loadData(this.onEditionId, null, this.onEditionIdx);
      };

      let $onError = (error) => {
        this.isEditOnload = false;
      };

      let $onComplete = () => {
        this.isEditOnload = false;
      };
      let diff = this.__.differ(this.dataArray[this.onEditionIdx], this.onEditionItem);
      diff.push('__id');
      
      let data = this._.pick(this.onEditionItem, diff);
      this.updateAction({ $onNext, $onError, $onComplete, data });
    },
    toggleActivateAction(id, val) {
      const findIdx = (element) => element.__id == id;
      var idx = this.dataArray.findIndex(findIdx);
      this.isListOnload = true;
      let $onNext = (response) => {
        this.dataArray[idx].__act = val;
      };

      let $onError = (error) => {
        this.isListOnload = false;
      };

      let $onComplete = () => {
        this.isListOnload = false;
      };

      let data = this._.pick(this.dataArray[idx], ['__id', '__act']);
      data.__act = val;
      this.updateAction({ $onNext, $onError, $onComplete, data });
    },
    deleteAction(id) {
      const findIdx = (element) => element.__id == id;
      var idx = this.dataArray.findIndex(findIdx);
      this.isListOnload = true;
      let $onNext = (response) => {
        if(response.data.result == 'OK') {
          this.dataArray.splice(idx, 1);
        }
      };

      let $onError = (error) => {
        this.isListOnload = false;
      };

      let $onComplete = () => {
        this.isListOnload = false;
      };

      let data = this._.pick(this.dataArray[idx], ['__id','__id_ref']);
      this.__.httpAction({
        methode: 'DELETE',
        route: this.moduleRoute,
        data: data,
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },
    editAction(id) {
      this.onEditionId = id;
      const findIdx = (element) => element.__id == id;
      this.onEditionIdx = this.dataArray.findIndex(findIdx);
      this.onEditionItem = this._.omit(this.dataArray[this.onEditionIdx ]);
    },
    updateAction(conf) {
      this.__.httpAction({
        methode: 'POST',
        route: this.moduleRoute,
        data: conf.data,
        onNext: conf.$onNext,
        onError: conf.$onError,
        onComplete: conf.$onComplete,
      });
    },
    addAction(addValue = '') {
      this.isListOnload = true;
      let $onNext = (response) => {
        this.loadData(response.data.result, () => {}
          //this.editAction(this.dataArray.length - 1)
        );
      };

      let $onError = (error) => {
        this.isListOnload = false;
      };

      let $onComplete = () => {
        this.isListOnload = false;
      };
      const insToUse = this.__payload.__useIns.replace("GRPID", this.useGrp);
      this.__.httpAction({
        methode: 'PUT',
        route: this.moduleRoute,
        data: {__useIns: insToUse+'__SPL__'+addValue},
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },
    loadData(id = null, cb = null, putAt = null) {
      let $onNext = (response) => {
        if (id != null) {
          if(putAt != null) {
            this.dataArray[putAt] = response.data.result;
          } else {
            this.dataArray.push(response.data.result);
          }
          
          if (cb) cb();
        } else {
          this.dataArray = response.data.result;
        }
      };

      let $onError = (error) => {
        this.isListOnload = false;
      };

      let $onComplete = () => {
        this.isListOnload = false;
      };

      this.isListOnload = true;
      var cndToUse = this.__payload.__useCnd.replace("GRPID", this.useGrp);
      this.__.httpAction({
        methode: 'GET',
        route: this.moduleRoute + (id != null ? id : ''),
        data: { __useCnd: cndToUse},
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },
  },
  watch: {  
    __payload: function () {
      if(this.$props.__payload !== undefined) {
        this.useFolder = this.__payload.__useFolder;
        this.useGrpList = this.__payload.__useGrpList;
        this.cancelAction()
        this.loadData();
      }
     
    },
    useGrp: function () {
      if(this.useGrp !== undefined) {
        this.loadData()
      }
    }
  },
  mounted() {
    setTimeout(()=> {
      this.useFolder = this.__payload.__useFolder;
      this.useGrpList = this.__payload.__useGrpList ?? [];
      this.useShowFileds = this.__payload.__useShowFileds ?? [];
      this.useCardStyle = this.__payload.__useCardStyle ?? '';
      this.loadData();
    }, 1000);
  },
};
</script>
<style>
</style>
