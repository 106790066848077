<template>
  <div class="row">
    <div class="col-md-9" v-if="onEditionId !== null">
      <Edit
        :__item="onEditionItem"
        :__isOnLoad="isEditOnload"
        :__payloadPhotos="payloadPhotos"
        :__payloadSeo="payloadSeo"
        :__payloadEdition="{
        }"
      >
      </Edit>
    </div>
    <div :class="listStyle">
      <List
        :__list="dataArray"
        :__isOnLoad="isListOnload"
        :__editMode="this.onEditionId != null"
        :__idxOnEdition="this.onEditionIdx"
      ></List>
    </div>
  </div>
</template>
<script>
import Edit from './Edit';
import List from './List';

export default {
  components: {
    Edit,
    List,
  },
  data() {
    return {
      moduleRoute: 'products/',
      payloadPhotos: {},
      payloadSeo: {},
      onEditionId: null,
      onEditionIdx: -1,
      onEditionItem: null,
      isListOnload: false,
      onGoingReq: [],
      isEditOnload: false,
      headerArray: ['Produit'],
      dataArray: [],
      // CategoryArray: [],
      // DesignerArray: [],
      // OptionsArray: [],
      // TagsArray: [],
    };
  },
  computed: {
    listStyle() {
      return this.onEditionId !== null ? 'col-md-3' : 'col-md-12';
    },
  },
  methods: {

    cancelAction() {
      this.onEditionId = null;
      this.onEditionIdx = -1;
      this.onEditionItem = null;
      this.payloadPhotos = {};
    },
    saveAction() {
      let $onNext = (response) => {
        this.loadData(this.onEditionId, null, this.onEditionIdx);
      };
      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };
      this.onGoingReq.push({});
      const beforParss = this._.omit(this.onEditionItem);
      // this.onEditionItem.__id_tags = this.onEditionItem.__id_tags
      //   ? this.onEditionItem.__id_tags.toString()
      //   : '';

      // this.onEditionItem.__id_cat = this.onEditionItem.__id_cat
      //   ? this.onEditionItem.__id_cat.toString()
      //   : '';
      // this.onEditionItem.__id_designer = this.onEditionItem.__id_designer
      //   ? this.onEditionItem.__id_designer.toString()
      //   : '';
      // this.onEditionItem.__id_opt_grp_list = this.onEditionItem.__id_opt_grp_list
      //   ? this.onEditionItem.__id_opt_grp_list.toString()
      //   : '';
      
      // this.onEditionItem.__id_opt_grp_photos = this.onEditionItem.__id_opt_grp_photos
      // ? this.onEditionItem.__id_opt_grp_photos.toString()
      // : '';

      let diff = this.__.differ(
        this.dataArray[this.onEditionIdx],
        this.onEditionItem
      );
      diff.push('__id');

      let data = this._.pick(this.onEditionItem, diff);
      this.onEditionItem = beforParss;
      this.updateAction({ $onNext, $onError, $onComplete, data });
    },
    toggleActivateAction(idx, val) {
      this.onGoingReq.push({});
      let $onNext = (response) => {
        this.dataArray[idx].__act = val;
      };
      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };

      let data = this._.pick(this.dataArray[idx], ['__id', '__act']);
      data.__act = val;
      this.updateAction({ $onNext, $onError, $onComplete, data });
    },
    deleteAction(idx) {
      this.onGoingReq.push({});
      let $onNext = (response) => {
        if (response.data.result == 'OK') {
          this.dataArray.splice(idx, 1);
        }
      };
      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };

      let data = this._.pick(this.dataArray[idx], ['__id', '__id_ref']);
      this.__.httpAction({
        methode: 'DELETE',
        route: this.moduleRoute,
        data: data,
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },

    editAction(idx) {
      this.onEditionId = this.dataArray[idx].__id;
      this.onEditionIdx = idx;
      this.onEditionItem = this._.omit(this.dataArray[idx]);
      // this.onEditionItem.__id_tags = this.onEditionItem.__id_tags
      //   ? this.onEditionItem.__id_tags.split(',')
      //   : [];
      // this.onEditionItem.__id_cat = this.onEditionItem.__id_cat
      //   ? this.onEditionItem.__id_cat.split(',')
      //   : [];
      // this.onEditionItem.__id_designer = this.onEditionItem.__id_designer
      //   ? this.onEditionItem.__id_designer.split(',')
      //   : [];
      // this.onEditionItem.__id_opt_grp_photos = this.onEditionItem.__id_opt_grp_photos
      //   ? this.onEditionItem.__id_opt_grp_photos.split(',')
      //   : [];
      // this.onEditionItem.__id_opt_grp_list = this.onEditionItem.__id_opt_grp_list
      // ? this.onEditionItem.__id_opt_grp_list.split(',')
      // : [];
      this.payloadPhotos = {
        __useCnd: '3__SPL__' + this.onEditionId + '__SPL__PRODUCT__SPL__GRPID',
        __useIns: '2__SPL__' + this.onEditionId + '__SPL__PRODUCT__SPL__GRPID',
        __useFolder: 'products/prd' + this.onEditionId,
      };
      this.payloadSeo = {
        __id: this.onEditionItem.__id_ref,
        __useCnd: '1__SPL__' + this.onEditionId,
      };
    },
    updateAction(conf) {
      this.__.httpAction({
        methode: 'POST',
        route: this.moduleRoute,
        data: conf.data,
        onNext: conf.$onNext,
        onError: conf.$onError,
        onComplete: conf.$onComplete,
      });
    },
    addAction() {
      this.onGoingReq.push({});
      let $onNext = (response) => {
        this.loadData(response.data.result, () =>
          this.editAction(this.dataArray.length - 1)
        );
      };
      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };

      this.__.httpAction({
        methode: 'PUT',
        route: this.moduleRoute,
        data: null,
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },

    formatJsonEntry(arr) {
      
      return arr.map(e => {
        var __categories = JSON.parse(e.__categories);
        var ret = [];
        if(__categories === null) {
          return {...e, __categories_filter: ret};
        }
        
        __categories.map(x => {
          ret.push(x.id);
          x.value.map(s => ret.push(s.id))
        })
        return {...e, __categories_filter: ret};
      });
    },

    loadData(id = null, cb = null, putAt = null) {
      let $onNext = (response) => {
        if (id != null) {
          if (putAt != null) {
            this.dataArray[putAt] = this.formatJsonEntry(response.data.result);
          } else {
            this.dataArray.push(this.formatJsonEntry(response.data.result));
          }

          if (cb) cb();
        } else {
          this.dataArray = this.formatJsonEntry(response.data.result);
        }
      };
      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };

      this.onGoingReq.push({});
      this.__.httpAction({
        methode: 'GET',
        route: this.moduleRoute + (id != null ? id : ''),
        data: null,
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },
  },
  watch: {
    onGoingReq: function () {
      this.isListOnload = this.onGoingReq.length > 0;
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
<style></style>
