<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <b-tabs content-class="mt-3">
      <b-tab title="PRODUIT" active >
        <b-overlay
          :show="__isOnLoad"
          variant="dark"
          rounded
          opacity="0.70"
          spinner-variant="primary"
          spinner-small
        >
          <card>
            <b-modal
              v-model="showUploader"
              scrollable
              :hide-footer="true"
              :hide-header="true"
              :dialog-class="dataUploaderPayload.customClass"
            >
              <galerie-manager
                :__payload="dataUploaderPayload"
              ></galerie-manager>
            </b-modal>

            <div class="text-right">
              <b-button variant="danger" @click="dispatchCancel()">
                Fermer
              </b-button>
              <b-button variant="success" @click="dispatchSave()">
                Enregistrer
              </b-button>
            </div>
            <div class="row" style="margin-left: 10px">
              <div class="col-md-3" style="float: ;left; min-height: 100px;">
                <div
                  class="row"
                  style="margin-bottom: 10px"
                  v-if="__item.__vignette == null"
                >
                  <b-button @click="showUploader = true" size="sm">
                    Ajouter vignette
                  </b-button>
                </div>
                <div class="row" style="margin-bottom: 10px" v-else>
                  <b-button @click="__item.__vignette = null" size="sm">
                    Supprimer vignette
                  </b-button>
                  <b-img
                    :src="__item.__vignette"
                    @click="showUploader = true"
                    fluid
                    style="width: 100%"
                  ></b-img>
                </div>
              </div>
              <div class="col-md-9 pr-md-1" style="float: right">
                <div class="col-md-12 pr-md-1">
                  <base-input
                    placeholder="Titre du produit"
                    v-model="__item.__title"
                  >
                  </base-input>
                </div>

                <div class="col-md-12 pr-md-1">
                  <base-input
                    placeholder="ID du configurateur"
                    v-model="__item.__id_conf"
                  >
                  </base-input>
                </div>

                <div class="col-md-12">
                  <base-input>
                    <textarea
                      rows="12"
                      cols="120"
                      class="form-control"
                      placeholder="Description"
                      v-model="__item.__desc"
                    >
                    </textarea>
                  </base-input>
                </div>
              </div>
            </div>
          </card>
          <div style="width: 49.5%; float: left">
            <data-manager :configModule="modules_diaptype" v-on="{updateByDataManager: onDiapTypeUPD}"></data-manager>
          </div>
          <div style="width: 50%; float: right">
            <data-manager :configModule="modules_pricing" v-on="{updateByDataManager: onPricingUPD}"></data-manager>
          </div>
          <div style="width: 100%">
            <select-manager
              v-for="(Module, mIdx) in modules_slect"
              :key="mIdx"
              :configModule="getConfigModuleToWarp(Module)"
            ></select-manager>
          </div>
        </b-overlay>
      </b-tab>
      <b-tab title="TEMPLATE">
        <div style="width: 100%">
          <template-manager :configModule="modules_template"></template-manager>
        </div>
      </b-tab>

      <b-tab title="STOCK">
        <div style="width: 100%">
          <stock-manager :configModule="modules_stock"></stock-manager>
        </div>
      </b-tab>

      <b-tab title="GALERIE">
        <Galerie :__payload="__payloadPhotos"></Galerie>
      </b-tab>
      <b-tab title="SEO">
        <Seo :__payload="__payloadSeo"></Seo>
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import Galerie from "../Galerie/Galerie";
import Seo from "../Seo/Seo";
export default {
  components: {
    Galerie,
    Seo,
  },
  props: {
    __isOnLoad: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    __item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    __payloadPhotos: {
      type: Object,
      default: () => {
        return {};
      },
    },
    __payloadSeo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    __payloadEdition: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data: () => {
    return {
      modules_slect: [],
      modules_template: {},
      modules_stock: {},
      modules_diaptype: {},
      modules_pricing: {},
      // categoryArray: [],
      // designerArray: [],
      // optionsArray: [],
      // tagsArray: [],
      showUploader: false,
      dataUploaderPayload: {
        folder: "",
        onFireCB: () => {},
        multiSelection: false,
        customClass: "customPrdEditModal",
      },
    };
  },
  methods: {
    onDiapTypeUPD(e) {
      this.__item.__diaptypes = e
      this.modules_stock = this.getConfigForStockMGT();
    },

    onPricingUPD(e) {
      this.__item.__pricing = e
      this.modules_stock = this.getConfigForStockMGT();
    },

    onStockUPD(e) {
      this.__item.__stock = e
    },

    dispatchSave() {
      this.$parent.saveAction();
    },

    dispatchCancel() {
      this.$parent.cancelAction();
    },

    getConfigModuleToWarp(Module) {
      const parentDependencies = this._.pick(this.__item, [
        "__id",
        Module.config.setFlag,
      ]);
      return { ...Module, dependency: Object.values(parentDependencies) };
    },

    getConfigModulesAsTpl() {
      const parentDependencies = this._.pick(this.__item, [
        "__id",
        "__modulesID",
      ]);
      return {
        modules: this.__.getMODULESByType("MODULE", "PRODUCT"),
        dependency: Object.values(parentDependencies),
        warpConfig: { route: "products/", flagDep: ["__id", "__modulesID"] },
      };
    },

    getConfigForStockMGT() {
      const parentDependencies = this._.pick(this.__item, [
        "__id",
        "__options",
        "__stock",
      ]);
      const overloadDependencies = this._.pick(this.__item, [
        "__vignette",
        "__title",
        "__pricing",
        "__diaptypes",
      ]);
      return {
        modules: this.__.getMODULESByID("PROD_OPTS"),
        dependency: Object.values(parentDependencies),
        overlapData: overloadDependencies,
        warpConfig: { route: "products/", flagDep: ["__id", "__stock"] },
      };
    },

    getConfigForPricingMGT() {
      const parentDependencies = this._.pick(this.__item, [
        "__id",
        "__pricing",
      ]);
      return {
        modules: null,
        dependency: Object.values(parentDependencies),
        warpConfig: { route: "products/", flagDep: ["__id", "__pricing"] },
        data: {
          title: "Prix",
          flg: [
            { title: "titre", type: "text", value: "DEFAULT", _w: "25%" },
            { title: "prix", type: "text", value: "0", _w: "25%" },
            {
              title: "cur",
              type: "list",
              _w: "10%",
              value: [
                { text: "euro", value: "€" },
                { text: "dolar", value: "$" },
              ],
            },
            { title: "prix barré", _w: "25%", type: "text", value: "" },
            {
              title: "tag",
              type: "list",
              _w: "15%",
              value: [
                { text: "", value: "" },
                { text: "Soldé", value: "Soldé" },
                { text: "Promo", value: "Promo" },
                { text: "Déstockage", value: "Déstockage" },
                { text: "Vente flash", value: "Vente flash" },
              ],
            },
          ],
          output: {
            titre: "",
            prix: "",
            cur: "",
            prixbarre: "",
            tag: "",
          },
        },
      };
    },

    getConfigForDiapMGT() {
      const parentDependencies = this._.pick(this.__item, [
        "__id",
        "__diaptypes",
      ]);
      return {
        modules: null,
        dependency: Object.values(parentDependencies),
        warpConfig: { route: "products/", flagDep: ["__id", "__diaptypes"] },
        data: {
          title: "Galeries photos",
          flg: [{ title: "titre", type: "text", _w: "100%", value: "DEFAULT" }],
          output: {
            titre: "",
          },
        },
      };
    },
    reloadConfigs () {
      this.modules_slect = this.__.getMODULESByType("SELECT", "PRODUCT");
      this.modules_stock = this.getConfigForStockMGT();
      this.modules_template = this.getConfigModulesAsTpl();
      this.modules_pricing = this.getConfigForPricingMGT();
      this.modules_diaptype = this.getConfigForDiapMGT();
    }
  },
  watch: {
    __item: function () {
      this.reloadConfigs()
    },
  },
  mounted() {
    this.modules_slect = this.__.getMODULESByType("SELECT", "PRODUCT");
    this.modules_stock = this.getConfigForStockMGT();
    this.modules_template = this.getConfigModulesAsTpl();
    this.modules_pricing = this.getConfigForPricingMGT();
    this.modules_diaptype = this.getConfigForDiapMGT();
    // this.categoryArray = this.$props.__payloadEdition.categoryArray;
    // this.optionsArray = this.$props.__payloadEdition.optionsArray;
    // this.designerArray = this.$props.__payloadEdition.designerArray;
    // this.tagsArray = this.$props.__payloadEdition.tagsArray;
    this.$props.__payloadPhotos.__useGrpList = (JSON.parse(this.$props.__item.__diaptypes).map(e => {return { text: e.data[0], value: e.data[0]}}))

    this.dataUploaderPayload.folder = "products/prd" + this.$props.__item.__id;
    this.dataUploaderPayload.onFireCB = (value) => {
      this.$props.__item.__vignette = value[0];
      this.showUploader = false;
    };
  },
};
</script>
<style>
.customPrdEditModal {
  max-width: 90%;
  top: 0px;
}

.custom-control-label {
  font-size: 10px !important;
}
</style>
