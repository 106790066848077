<template>
  <div class="row">
    <div class="col-md-12" v-if="onEditionId !== null">
      <Edit
        :__item="onEditionItem"
        :__isOnLoad="isEditOnload"
        :__payloadPhotos="payloadPhotos"
        :__useShowFileds="useShowFileds.edition"
        :__useDropDown="useShowFileds.dropDown"
      ></Edit>
    </div>
    <div :class="listStyle" v-if="onEditionId == null">
      <List
        :__list="dataArray"
        :__isOnLoad="isListOnload"
        :__editMode="this.onEditionId != null"
        :__idxOnEdition="this.onEditionIdx"
        :__useShowFileds="useShowFileds.list"
        :__useDropDown="useShowFileds.dropDown"
        :__navigationArray="navigationArray"
      ></List>
    </div>
  </div>
</template>
<script>
import Edit from "./Edit";
import List from "./List";
export default {
  components: {
    Edit,
    List,
  },
  props: {
    PID: {
      type: Number | String,
      default: () => {
        return -1;
      },
    },
    CONFIG: {
      type: String,
      default: () => {
        return "DEFAULT";
      },
    },
  },
  data() {
    return {
      moduleRoute: "options/",
      payloadPhotos: {},
      onEditionId: null,
      onEditionIdx: -1,
      onEditionItem: null,
      isListOnload: false,
      isEditOnload: false,
      dataArray: [],
      useShowFileds: { edition: [], list: [], dropDown: [] },
      navigationArray: [],
      onGoingReq: [],
    };
  },
  computed: {
    listStyle() {
      return this.onEditionId !== null ? "col-md-12" : "col-md-12";
    },
  },
  methods: {
    loadNavigationData(route) {
      let $onNext = (response) => {
        response.data.result.map(e => {
          this.navigationArray.push({ text : e.__name, value: e.__id, type: route})
        });
      };
      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };
      this.onGoingReq.push({});
      this.__.httpAction({
        methode: 'GET',
        route: route,
        data: null,
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },

    cancelAction() {
      this.onEditionId = null;
      this.onEditionIdx = -1;
      this.onEditionItem = null;
      this.payloadPhotos = {};
    },
    saveAction() {
      
      let $onNext = (response) => {
        this.loadData(this.onEditionId, null, this.onEditionIdx);
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };
      this.onGoingReq.push({});
      let diff = this.__.differ(
        this.dataArray[this.onEditionIdx],
        this.onEditionItem
      );
      diff.push("__id");
      let data = this._.pick(this.onEditionItem, diff);
      this.updateAction({ $onNext, $onError, $onComplete, data });
    },
    toggleActivateAction(id, val) {
      const findIdx = (element) => element.__id == id;
      var idx = this.dataArray.findIndex(findIdx);

      let $onNext = (response) => {
        this.dataArray[idx].__act = val;
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };
      this.onGoingReq.push({});

      let data = this._.pick(this.dataArray[idx], ["__id", "__act"]);
      data.__act = val;
      this.updateAction({ $onNext, $onError, $onComplete, data });
    },
    deleteAction(id) {
      const findIdx = (element) => element.__id == id;
      var idx = this.dataArray.findIndex(findIdx);

      let $onNext = (response) => {
        if (response.data.result == "OK") {
          this.dataArray.splice(idx, 1);
        }
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };
      this.onGoingReq.push({});

      let data = this._.pick(this.dataArray[idx], ["__id"]);
      this.__.httpAction({
        methode: "DELETE",
        route: this.moduleRoute,
        data: data,
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },
    editAction(id) {
      const findIdx = (element) => element.__id == id;
      var idx = this.dataArray.findIndex(findIdx);

      this.onEditionId = id;
      this.onEditionIdx = idx;
      this.onEditionItem = this._.omit(this.dataArray[idx]);
    },
    updateAction(conf) {
      this.__.httpAction({
        methode: "POST",
        route: this.moduleRoute,
        data: conf.data,
        onNext: conf.$onNext,
        onError: conf.$onError,
        onComplete: conf.$onComplete,
      });
    },
    addAction() {
      this.isListOnload = true;
      let $onNext = (response) => {
        this.loadData(response.data.result, () =>
          this.editAction(response.data.result)
        );
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };
      this.onGoingReq.push({});
      this.__.httpAction({
        methode: "PUT",
        route: this.moduleRoute,
        data: { __useIns: "1__SPL__" + this.PID + "__SPL__ " },
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },

    loadData(id = null, cb = null, putAt = null) {
      let $onNext = (response) => {
        if (id != null) {
          if (putAt != null) {
            this.dataArray[putAt] = response.data.result;
          } else {
            this.dataArray.push(response.data.result);
          }

          if (cb) cb();
        } else {
          this.dataArray = response.data.result;
        }
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };
      this.onGoingReq.push({});

      this.__.httpAction({
        methode: "GET",
        route: this.moduleRoute + (id != null ? id : ""),
        data: { __useCnd: "2__SPL__" + this.PID },
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },
    getFiledToUseByConfig() {
      switch (this.CONFIG) {
        case "PROD_OPTS":
        case "PROD_ACHAT":
          this.useShowFileds = {
            list: ["__title", "__desc"],
            edition: [
              "__title",
              "__desc",
              "__picto",
              "__price",
              "__price_impact",
            ],
          };
          break;
        case "PROD_FILTER_MENU":
          this.useShowFileds = {
            list: ["__title", "__picto"],
            edition: [
              "__title",
              "__config",
              "__picto",
            ],
          };
          break;
        case "PROD_DESC":
          this.useShowFileds = {
            list: ["__title", "__picto"],
            edition: [
              "__title",
              "__html",
              "__picto",
              "__btnLabel",
              "__btnLink",
            ],
          };
          break;
        case "PROD_DESIGNER":
          this.useShowFileds = {
            list: ["__title", "__desc", "__picto"],
            edition: [
              "__title",
              "__desc",
              "__picto",
              "__btnLabel",
              "__btnLink",
            ],
          };
          break;
        case "BLOC_ADRESSE":
          this.useShowFileds = {
            list: ["__title", "__desc", "__picto"],
            edition: [
              "__title",
              "__desc",
              "__html",
              "__config",
              "__picto",
              "__btnLabel",
              "__btnLink",
            ],
          };
          break;
        case "PROD_PARALAX":
          this.useShowFileds = {
            list: ["__title", "__desc", "__picto"],
            edition: [
              "__title",
              "__desc",
              "__picto",
              "__pictoOver",
              "__btnLabel",
              "__btnLink",
            ],
          };
          break;
        case "BLOC_TEXT":
        case "FAQ":
          this.useShowFileds = {
            list: ["__title", "__html"],
            edition: ["__title", "__html"],
          };
          break;
        case 'PROD_GRID':
        this.useShowFileds = {
            list: ["__title"],
            edition: ["__title", "__config", "__html"],
          };
          break;
        case "PROD_SPEC":
        case "PROD_PLUS":
          this.useShowFileds = {
            list: ["__title", "__desc"],
            edition: ["__title", "__desc", "__config", "__picto"],
          };
          break;
        case "POPUP":
        this.useShowFileds = {
            list: ["__title"],
            edition: ["__title", "__html", "__picto", "__config"],
          };
          break;
        case "SCROLL":
        case "SCROLL_HOME":
        case "PROD_TAGS":
        case 'PROD_CAT':
          this.useShowFileds = {
            list: ["__title"],
            edition: ["__title"],
          };
          break;
        case "POINTS_FORTS":
          this.useShowFileds = {
            list: ["__title", "__icone"],
            edition: ["__title", "__icone"],
          };
          break;
        case "BLOC_VIDEO":
          this.useShowFileds = {
            list: ["__title", "__video"],
            edition: ["__title", "__video", "__config"],
          };
          break;
          
        case "NAV_MENU":
          this.useShowFileds = {
            list: ["__title", "__icone", "__btnLabel"],
            edition: ["__title", "__icone"],
            dropDown: [
              () => ({
                warperDep: 0,
                warper: '__btnLabel',
                data: () => ([{text: 'Externe', value: 'Externe'},{text: 'Script', value: 'Script'},{text: 'Page', value: 'Page'}, {text: 'Boutique', value: 'Boutique'}])
              }),
              () => ({
                warperDep: 0,
                warper: '__btnLink',
                data: (dep) => {
                  if(dep === "Page") {
                    return this.navigationArray.filter(e => e.type === 'pages/')
                  }
                  if(dep === "Boutique") {
                    return this.navigationArray.filter(e => e.type === 'shops/')
                  }
                  return [];
                }
              })
           ]
          };
          break;
        case "PROD_DETAIL":
          this.useShowFileds = {
            list: ["__title", "__picto"],
            edition: [
              "__title",
              "__html",
              "__picto",
              "__pictoOver",
            ],
          };
          break;

        case "BLOC_IMGS":
          this.useShowFileds = {
            list: ["__picto", "__btnLabel"],
            edition: ["__picto"],
            dropDown: [
              () => ({
                warperDep: 0,
                warper: '__btnLabel',
                data: () => ([{text: 'Page', value: 'Page'}, {text: 'Boutique', value: 'Boutique'}, {text: 'Produit', value: 'Produit'}])
              }),
              () => ({
                warperDep: 0,
                warper: '__btnLink',
                data: (dep) => {
                  if(dep === "Page") {
                    return this.navigationArray.filter(e => e.type === 'pages/')
                  }
                  if(dep === "Boutique") {
                    return this.navigationArray.filter(e => e.type === 'shops/')
                  }
                  if(dep === "Produit") {
                    return this.navigationArray.filter(e => e.type === 'products/')
                  }
                  return [];
                }
              })
           ]
          };
          break;
      }
    },
  },
  mounted() {
    this.getFiledToUseByConfig();
    this.loadData();
    if(["NAV_MENU", "BLOC_CONTACT", "BLOC_IMGS"].includes(this.CONFIG)) {
      this.loadNavigationData('pages/')
      this.loadNavigationData('shops/')
      this.loadNavigationData('products/')
    }

  },

  watch: {
    onGoingReq: function () {
      this.isListOnload = this.isEditOnload = this.onGoingReq.length > 0;
    },
  }
};
</script>
<style></style>
