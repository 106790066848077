<!-- eslint-disable vue/no-mutating-props -->
<template>
   <b-overlay
        :show="__isOnLoad"
        variant="dark"
        rounded
        opacity="0.70"
        spinner-variant="primary"
        spinner-small
      >
        <card>
          <b-modal 
          v-model="showUploader" 
          scrollable 
          :hide-footer= "true"
          :hide-header= "true"
          :dialog-class = "dataUploaderPayload.customClass"
          >
            <galerie-manager :__payload="dataUploaderPayload"></galerie-manager>
          </b-modal>
          <b-img :src="__item.__url" @click="showUploader = true" fluid style="min-width: 100%;"></b-img>
          
            <div class="col-md-8 pr-md-1" style="float: left ;"  v-if="__useShowFileds.length>0 && __useShowFileds.includes('__title')">
              <base-input
                placeholder="Titre"
                v-model="__item.__title"
              >
              </base-input>
            </div>

            <div class="col-md-2 pr-md-1" style="float: left ;"  v-if="__useShowFileds.length>0 && __useShowFileds.includes('__price_impact')">
              <b-form-select v-model="__item.__price_impact" class="form-control">
                <b-form-select-option :value="0" style="color: #000;">Gratuite</b-form-select-option>
                <b-form-select-option value="1" style="color: #000;">Variant</b-form-select-option>
                <b-form-select-option value="2" style="color: #000;">Extra</b-form-select-option>
                <b-form-select-option value="3" style="color: #000;">Pourcentage</b-form-select-option>
              </b-form-select>
            </div>

            <div class="col-md-2 pr-md-1" style="float: left ;"  v-if="__useShowFileds.length>0 && __useShowFileds.includes('__price')">
              <base-input
                placeholder="Coût"
                v-model="__item.__price"
              >
              </base-input>
            </div>


            <div class="col-md-12" style="clear: both;"></div>
            <div class="col-md-12 pr-md-1" v-if="__useShowFileds.length>0 && __useShowFileds.includes('__link')">
              <base-input
                v-model="__item.__link"
                placeholder="Lien sur cette photo vers"
              >
              </base-input>
            </div>
            <div class="col-md-12" v-if="__useShowFileds.length>0 && __useShowFileds.includes('__desc')">
              <base-input>
                <textarea
                  rows="4"
                  cols="120"
                  class="form-control"
                  placeholder="Description"
                  v-model="__item.__desc"
                >
                </textarea>
              </base-input>
            </div>
           

          <div class="row" v-if="__useShowFileds.length>0 && __useShowFileds.includes('__html')">
            <div class="col-md-12">
              <wysiwyg v-model="__item.__html" />
            </div>
          </div>
          <div class="text-right">
            <b-button variant="danger" @click="dispatchCancel()"> Fermer </b-button>
            <b-button variant="success" @click="dispatchSave()">
              Enregistrer
            </b-button>
          </div>
        </card>
        </b-overlay>
</template>
<script>
import Galerie from '../Galerie/Galerie';
export default {
  components: {
    Galerie,
  },
  props: {
    __isOnLoad: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    __item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    __payloadPhotos: {
      type: Object,
      default: () => {
        return {};
      },
    },
    __useFolder: {
      type: String,
      default: () => {
        return '';
      },
    },
    __useShowFileds: {
      type: String,
      default: () => {
        return [];
      },
    }
  },
  data: () => {
    return {
      showUploader: false,
      dataUploaderPayload: {
        folder: "",//this.$parent.__payload.__useFolder,
        onFireCB: ()=>{},
        multiSelection: false,
        customClass: "customPhotosEditModal"
      }
    }
  },
  methods: {
    dispatchSave() {
      this.$parent.saveAction();
    },
    dispatchCancel() {
      this.$parent.cancelAction();
    },
  },
  watch: {  
    showUploader: function () {
      this.dataUploaderPayload.folder = this.__useFolder;
    },
  },
  mounted() {
    this.dataUploaderPayload.folder = this.$parent.__payload.__useFolder;
    this.dataUploaderPayload.onFireCB = (value) => {
      this.$props.__item.__url = value[0];
      this.showUploader = false;
    }
  }
};
</script>
<style>

.customPhotosEditModal {
    max-width: 90%;
    top: 0px;
}
</style>
